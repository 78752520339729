<template>
  <div>
    <el-dialog
      title="常规巡检"
      :visible.sync="detailVisible"
      @close="backClick"
      class="dialog-class"
      width="80%"
    >
      <div class="title-class">
        <div class="icon-class"></div>
        <div class="text-class">巡检计划</div>
      </div>
      <div class="plan-class">
        <div class="form-class" v-for="(item, index) in showForm" :key="index">
          <div class="label-class">{{ item.label }}：</div>
          <div class="data-class">{{ item.data }}</div>
        </div>
      </div>

      <div class="title-class">
        <div class="icon-class"></div>
        <div class="text-class">巡检记录</div>
      </div>
      <el-table
        :data="tableData"
        border
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="巡检类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.pollingType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="巡视开始时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.starttime }}</span>
          </template>
        </el-table-column>

        <el-table-column label="巡视结束时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.endtime }}</span>
          </template>
        </el-table-column>

        <el-table-column label="协作人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.collaborator }}</span>
          </template>
        </el-table-column>

        <el-table-column label="附件" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.class }}</span>
          </template>
        </el-table-column>

        <el-table-column label="是否完成" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.yesandno ? "是" : "否" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="负责人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.principal }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
      <div class="button-class">
        <el-button type="primary" @click="backClick">返回</el-button>
        <el-button type="primary" @click="submitClick">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    detailId: {
      typeof: Number,
      default: null,
    },
  },
  mounted() {
    this.getDetail();
    this.getTableList();
  },
  data() {
    return {
      detailVisible: true,
      showForm: [
        { label: "首次工作日期", name: "starttime", data: "" },
        { label: "下次工作日期", name: "nextworkingtime", data: "" },
        { label: "频次", name: "frequency", data: null },
        { label: "是否停用", name: "situation", data: null },
        { label: "巡视类型", name: "pollingType", data: null },
        { label: "次数", name: "pollinglimit", data: null },
        { label: "协作人", name: "collaborator", data: null },
        { label: "记录人", name: "principal", data: null },
        { label: "计划巡视工期（天）", name: "duration", data: null },
        { label: "备注", name: "descr", data: null },
      ],
      tableData: [],
      total: 0,
      size: 10,
      currentPage: 1,
    };
  },
  methods: {
    submitClick() {},
    backClick() {
      this.detailVisible = false;
      this.$emit("closeAddDialog");
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    // 获取详情
    getDetail() {
      this.$http
        .post("pollingPlan/queryOne", { id: this.detailId })
        .then((res) => {
          if (res.data.code == 200) {
            Object.keys(res.data.data).forEach((item) => {
              this.showForm.forEach((i) => {
                if (i.name == item) {
                  i.data = res.data.data[item];
                  if (i.name === "situation") {
                    i.data = res.data.data[item] === 1 ? "是" : "否";
                  }
                }
              });
            });
            this.showForm[3].data = this.showForm[3].data === 1 ? "是" : "否";
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    getTableList() {
      let params = {
        pageSize: this.size,
        currPage: this.currentPage,
        condition: {
          planId: this.detailId,
        },
      };
      //
      this.$http.post("pollingPlanRecord/list", params).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-class {
  .title-class {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .icon-class {
      width: 5px;
      height: 23px;
      border-radius: 5px;
      border: 1px solid #02a7f0;
      background-color: #02a7f0;
    }
    .text-class {
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #333333;
      font-size: 16px;
      margin-left: 20px;
    }
  }
  .plan-class {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-class {
      display: flex;
      align-content: center;
      width: 33%;
      font-size: 17px;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #333333;
      .label-class {
        width: 60%;
        margin-right: 20px;
        display: flex;
        justify-content: right;
      }
    }
  }
  .button-class {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
  }
  /deep/ .el-table--border th {
    background-color: #d7d7d7;

    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #000000;
  }
  /deep/ .el-table td {
    background-color: #ffffff;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #555555;
  }
  /deep/ .el-table__body-wrapper {
    background: #ffffff;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  /deep/ .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  /deep/ .el-pagination button:disabled {
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #333;
    background-color: #fff;
    cursor: default;
  }
  /deep/ .el-pager li {
    background: none;
    color: #333;
  }
  /deep/ .el-icon {
    color: #333;
  }
  /deep/ .el-pagination .btn-next {
    background: none;
  }
  /deep/ .el-pagination__total {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
  /deep/ .el-pagination__jump {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
  /deep/ .el-input__inner {
    color: #333;
  }
}
</style>
