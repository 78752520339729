<template>
  <div>
    <el-dialog
      :title="isAdd ? '新增巡检类型' : '修改巡检类型'"
      :visible.sync="addDialogVisible"
      @close="close('form')"
      class="form-class"
      width="800px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="form"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="首次工作日期" required prop="starttime">
              <el-date-picker
                v-model="ruleForm.starttime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="次数" prop="pollinglimit">
              <el-input
                type="pollinglimit"
                v-model="ruleForm.pollinglimit"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="下次工作日期" prop="nextworkingtime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.nextworkingtime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="协作人" prop="collaborator">
              <el-select v-model="ruleForm.collaboratorList" multiple placeholder="请选择" @change="getcollaborator">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="频次" prop="frequency">
              <el-select v-model="ruleForm.frequency" placeholder="请选择频次">
                <el-option value="每周"></el-option>
                <el-option value="每月"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="记录人" prop="principal">
              <el-select v-model="ruleForm.principal" placeholder="请选择" @change="getprincipalCode">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="是否停用" prop="situation">
              <el-select v-model="ruleForm.situation" placeholder="请选择是否停用">
                <el-option label="是" :value="0"></el-option>
                <el-option label="否" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="计划巡视工期（天）"
              prop="duration"
              class="largetext"
            >
              <el-input type="number" v-model="ruleForm.duration"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡视类型" prop="pollingType">
              <el-select v-model="ruleForm.pollingType" placeholder="请选择">
                <el-option
                  v-for="item in classOptions"
                  :key="item.id"
                  :label="item.pollingType"
                  :value="item.pollingType"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="descr">
              <el-input style="width: 100%" v-model="ruleForm.descr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')"
              >提交</el-button
            >
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../../utils/formatDate.js";
export default {
  props: {
    // 0 编辑 1添加
    isAdd: {
      typeof: Number,
      default: null,
    },
    queryOneId: {
      typeof: Number,
      default: null,
    },
  },
  mounted() {
    // 获取详情
    if (this.isAdd === 0 && this.queryOneId) {
      this.getDetail();
    }
    this.userList();
    this.classList();
  },
  data() {
    return {
      addDialogVisible: true,
      ruleForm: {
        starttime: "",
        nextworkingtime: "",
      },
      userOptions: [],
      userOptionsData: {},
      classOptions: [], //巡检记录列表
      rules: {
        starttime: [
          { required: true, message: "请选择首次日期", trigger: "blur" },
        ],
        pollinglimit: [
          { required: true, message: "请输入次数", trigger: "blur" },
        ],
        // nextworkingtime: [ { required: true, message: '请选择下次日期', trigger: 'blur' }],
        // collaborator: [ { required: true, message: '请选择协作人', trigger: 'blur' }],
        frequency: [{ required: true, message: "请选择频次", trigger: "blur" }],
        // principal: [
        //   { required: true, message: "请选择记录人", trigger: "blur" },
        // ],
        situation: [
          { required: true, message: "请选择是否停用", trigger: "blur" },
        ],
        duration: [
          {
            required: true,
            message: "请输入计划巡视工期（天）",
            trigger: "blur",
          },
        ],
        pollingType: [
          { required: true, message: "请输入巡视类型", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    close(formName) {
      this.addDialogVisible = false;
      this.resetForm(formName);
      this.$emit("closeAddDialog");
    },
    async submit() {
      let that = this;
      //报错-保存
      this.ruleForm.principal = '1'
      this.ruleForm.principalCode = '1'

      that.ruleForm.starttime = formatDate(
        new Date(that.ruleForm.starttime),
        "yyyy-MM-dd 00:00:00"
      );
      console.log('nextworkingtime',that.ruleForm.nextworkingtime)
      if(that.ruleForm.nextworkingtime){
        that.ruleForm.nextworkingtime = formatDate(
          new Date(that.ruleForm.nextworkingtime),
          "yyyy-MM-dd 00:00:00"
        );
      }
      this.ruleForm.powerStationCode = localStorage.getItem("powerStationCode");
      this.ruleForm.powerStationName = localStorage.getItem("powerStationName");
      // delete this.ruleForm.collaboratorList
      let res = await this.$http.post("pollingPlan/save", that.ruleForm);
      if (res.data.code == 200) {
        if (this.isAdd) {
          that.$notify.success({
            title: "提示",
            message: "添加成功",
            showClose: true,
          });
        } else {
          that.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true,
          });
        }
        this.addDialogVisible = false;
        this.$emit("closeAddDialog");
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 人员列表 engineeringDept/queryDeptAndEmployee pollingPlan/userList
    async userList() {
      const that = this
      let res = await this.$http.post("engineeringDept/queryDeptAndEmployee",{});
      if (res.data.code == 200) {
        this.userOptions = res.data.data.map(item=>{
          that.userOptionsData[item.name] = item
          return item
        })
      } else {
        this.$message.error(res.data.message);
      }
    },
    getcollaborator(val){
      // let index = this.userOptions.findIndex(item=>item.name == val)
      // this.ruleForm.collaboratorCode = this.userOptions[index].userCode
      this.ruleForm.collaborator = val.join(';')
      this.ruleForm.collaboratorCode = val.map(item=>{
        return this.userOptionsData[item].userCode
      }).join(';')
      console.log('getcolla34-0borator',val,this.ruleForm.collaboratorCode)
    },
    getprincipalCode(val){
      let index = this.userOptions.findIndex(item=>item.name == val)
      this.ruleForm.principalCode = this.userOptions[index].userCode
    },
    // 巡检类型列表
    async classList() {
      let params = {
        pageSize: 10000,
        currPage: 1,
      };
      // params.condition.powerStationCode = localStorage.getItem("powerStationCode");
      let res = await this.$http.post("polling/list", params);
      if (res.data.code == 200) {
        this.classOptions = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 获取详情
    async getDetail() {
      let res = await this.$http.post("pollingPlan/queryOne", {
        id: this.queryOneId,
      });
      if (res.data.code == 200) {
        this.ruleForm = res.data.data;
        this.ruleForm.collaboratorList = this.ruleForm.collaborator.split(';')
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-class {
  /deep/ .el-form {
    display: block;
  }
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 115px;
    margin-bottom: 5px;
    text-align: right;
  }
  /deep/.largetext .el-form-item__label{
    width: 150px;
    padding-right: 0;
  }
  /deep/.el-form-item__content{
    flex-grow: 1;
  }
  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
    width: 100%;
  }
  /deep/ .el-select ,/deep/.el-input{
    width: 100%;
  }
}

::v-deep .el-checkbox {
  margin-right: 0;
}
</style>
