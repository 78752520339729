<template>
  <div class="body">
    <div class="home-class">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <!-- <el-form-item label="电站">
          <el-input
            v-model="formInline.powerStationName"
            placeholder=""
          ></el-input>
        </el-form-item> -->
        <el-form-item label="巡视类型">
          <el-input
            v-model="formInline.pollingType"
            placeholder="巡视类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="协作人">
          <el-input
            v-model="formInline.collaborator"
            placeholder="协作人"
          ></el-input>
        </el-form-item>
        <el-form-item label="首次工作时间">
          <el-date-picker
            placeholder="选择时间"
            v-model="formInline.starttime"
            type="datetime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="hand-class">
        <img
          src="../../assets/images/yunwei/add.png"
          @click="editClick(1)"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/edit.png"
          @click="editClick(0)"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/delete.png"
          @click="deleteClick"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/export.png"
          @click="exportClick"
          alt=""
        />
      </div>
      <div class="header-input">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          style="width: 100%; height: 71vh; background: none"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50" align="center">
          </el-table-column>
          <el-table-column label="首次工作时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.starttime }}</span>
            </template>
          </el-table-column>

          <el-table-column label="下次工作时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.nextworkingtime }}</span>
            </template>
          </el-table-column>

          <el-table-column label="频次" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.frequency }}</span>
            </template>
          </el-table-column>

          <el-table-column label="次数" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.pollinglimit }}</span>
            </template>
          </el-table-column>

          <el-table-column label="计划巡视工期（天）" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.duration }}</span>
            </template>
          </el-table-column>

          <el-table-column label="巡查类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.pollingType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="协作人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.collaborator }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="记录人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.principal }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="detailClick(scope.row.id)"
                >详情</el-button
              >
              <el-button type="text" @click="add(scope.row)">分配</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[2, 5, 10, 15]"
          :page-size="size"
          class="pagination-style"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div v-if="addDialogVisible == true">
        <add-plan-dialog
          :addDialogVisible="addDialogVisible"
          :isAdd="isAdd"
          :queryOneId="queryOneId"
          @closeAddDialog="closeAddDialog"
        ></add-plan-dialog>
      </div>
      <div v-if="detailDialogVisible == true">
        <plan-detail
          :detailId="detailId"
          :detailDialogVisible="detailDialogVisible"
          @closeAddDialog="detailDialogVisible = false"
        ></plan-detail>
      </div>
    </div>

    <el-dialog title="分配电站" :visible.sync="dialogVisible" width="50%">
      <el-table
        :data="powerStationList"
        border
        @selection-change="handleSelectionChange2"
        height="530px"
        ref="multipleTable"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="powerStationName"
          label="电站名称"
          align="center"
          show-overflow-tooltip
        >

        <template v-slot="{row}">
          <span  style="color:red" >{{row.powerStationName}}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="公司"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="queryInfo2.currPage"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="queryInfo2.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total2"
      >
      </el-pagination> -->
      <el-row type="flex" justify="center" style="margin-top: 10px">
        <el-button type="primary" @click="handleAddSubmit">添加</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../utils/formatDate";
import AddPlanDialog from "./dialog/addPlanDialog.vue";
import PlanDetail from "./dialog/planDetail.vue";
export default {
  components: {
    AddPlanDialog,
    PlanDetail,
  },
  mounted() {
    this.getListData();
  },
  data() {
    return {
      formInline: {
        starttime: "", //首次工作时间
        // powerStationName: localStorage.getItem("powerStationName"), //电站名称
      },
      tableData: [{ class: 1 }, { class: 2 }],
      total: 0,
      size: 10,
      currentPage: 1,
      addDialogVisible: false, //是否显示新增/编辑弹框
      isAdd: 1, // 0编辑 1新增
      isBan: true, //是否禁止添加和编辑
      detailDialogVisible: false, // 是否打开详情弹框
      queryOneId: null, //单行数据id
      detailId: null,
      powerStationList: [],
      dialogVisible: false, //是否显示分配弹框
      submitList: [],
      queryInfo: {
        condition: {},
        currPage: 1,
        pageSize: 100000,
      },
      submitData: {},
    };
  },
  created() {
    console.log(this.$route.fullPath, 176);
    console.log("kkkkkkkkkkkkkkkkkkk");
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getListData();
    },
    handleCurrentChange() {
      this.currentPage = val;
      this.getListData();
    },
    // 新增/编辑
    editClick(key) {
      if (key === 0 && this.isBan) return;
      this.isAdd = key;
      this.addDialogVisible = true;
    },
    async add(row) {
      await this.getPowerStation();
      this.submitData = row;
      this.selectList = row.powerStationCode.split(",");

      this.dialogVisible = true;
      this.huixian(this.selectList);
    },
    async huixian(list) {
      const res2 = this.powerStationList.filter((item) => {
        console.log(item, list, 139);
        return list.some((ele) => {
          return item.powerStationCode.includes(ele);
        });
      });
      console.log(res2, 692);

      this.$nextTick(() => {
        if (res2) {
          this.$refs.multipleTable.clearSelection();
          res2.forEach((item) => {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    // 获取数据
    async getPowerStation() {
      this.queryInfo.condition.userCode = this.userCode;
      const res = await this.$http.post("/powerStation/list", this.queryInfo);
      if (res.data.code === 200) {
        this.total = res.data.data.count;
        // this.queryInfo.currPage = res.data.data.currPage;
        this.powerStationList = res.data.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
    // 添加
   async  handleAddSubmit() {

      this.submitData.powerStationCode = this.submitList.map(item=>item.powerStationCode).join(",")
      this.submitData.powerStationName = this.submitList.map(item=>item.powerStationName).join(",")
       const res = await this.$http.post("pollingPlan/save", this.submitData);
        if (res.data.code === 200) {
          this.dialogVisible = false;
          this.$message.success("分配成功");
          this.getListData();
        } else {
          this.$message.error(res.data.message);
        }
    },
    handleSelectionChange2(val) {
      this.submitList = val;
    },
    //表格勾选
    handleSelectionChange(val) {
      this.selectList = [];
      val.forEach((item) => {
        this.selectList.push(item.id);
      });
      if (val.length != 1) {
        this.isBan = true;
      } else {
        this.isBan = false;
        this.queryOneId = val[0].id;
        console.log("表格勾选", this.queryOneId, val);
      }
    },
    // 查询
    onSubmit() {
      this.getListData();
    },
    addClick() {
      this.addDialogVisible = true;
    },
    // 详情
    detailClick(id) {
      this.detailDialogVisible = true;
      this.detailId = id;
    },
    closeAddDialog() {
      this.addDialogVisible = false;
      this.getListData();
    },
    //删除
    async deleteClick() {
      if (this.selectList.length < 1) return;
      let delList = this.selectList;
      let res = await this.$http.post("pollingPlan/deleteBatch", [...delList]);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
        this.getListData();
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 列表
    async getListData() {
      if (this.formInline.starttime) {
        this.formInline.starttime = formatDate(
          new Date(this.formInline.starttime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
      let params = {
        pageSize: this.size,
        currPage: this.currentPage,
        condition: this.formInline,
      };
      console.log("params", params);
      // params.condition.powerStationCode =
      //   localStorage.getItem("powerStationCode");
      // params.condition.powerStationName =
      //   localStorage.getItem("powerStationName")
      let res = await this.$http.post("pollingPlan/list", params);
      if (res.data.code == 200) {
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 导出
    async exportClick() {
      let res = await this.$http.post("pollingPlan/Exp");
      if (res.data.code == 200) {
        window.open(res.data.data.data);
      } else {
        this.$message.error(res.data.message);
      }
    },
    //删除
    async deleteClick() {
      let delList = this.selectList;
      let res = await this.$http.post("pollingPlan/deleteBatch", [...delList]);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
        this.getListData();
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #223f6c;
  // width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  .home-class {
    height: 88vh;
    padding: 8px;
    background-color: #2d5981;
    box-sizing: border-box;

    .hand-class {
      display: flex;
      text-align: right;
      float: right;
      margin-bottom: 5px;

      img {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        margin: 0 5px;
      }
    }
  }
}

/deep/ .el-form {
  display: flex;
}

/deep/ .el-form-item {
  display: flex;
  align-items: center;
}

/deep/ .el-form-item__label {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
  text-align: left;
  line-height: normal;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}



::v-deep .el-checkbox {
  margin-right: 0;
}
.header-input {

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

  /deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}


/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

}


/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;

  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}



/deep/ .el-pagination .btn-prev {
  background: none;
}

/deep/ .el-pagination .btn-next {
  background: none;
}

// ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
//   background-color: #f59a23 !important;
// }

</style>
